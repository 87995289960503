<template>
  <div style="width:100%;height: 100%;overflow-y: auto" >

    <a-list bordered style=" height: 350px; overflow-y: auto;  margin: 5px; padding: 5px;" item-layout="horizontal" :data-source="data" >
      <a-list-item slot="renderItem" slot-scope="item" >
        <a slot="actions" :style="{color:(item.isSelected?'#f00':'#40a9ff')}" @click="clickUser(item)">{{item.isSelected?"已选择":"未选择"}}</a>
        <a-list-item-meta  :description="item.openid">
          <a slot="title" >{{ item.nickname }}</a>
          <a-avatar  slot="avatar"  :src="item.headimgurl"/>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
    <br>
    <br>
    <span :style="{'color':'#40a9ff','width':'100%','font-size': 'small','font-weight': 'bold','borderBottom': '1px solid #E9E9E9'}">所选用户-{{selectedUser.nickname}}-功能设定</span>
    <br>
    <div >

      <br />
      <a-checkbox-group  style="font-size: small;padding: 5px" v-model="checkedList" :options="funOptions" @change="onChange" />
      <br />
      <div :style="{ borderTop: '1px solid #E9E9E9','margin-top': '10px', 'padding-top':' 10px' }">
        <a-checkbox style="font-size: small;font-weight: bold;padding: 5px" :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
          全选
        </a-checkbox>
        <a-button type="primary" style="right: 10px;position: absolute;" icon="save" @click="updateUser">
          更新
        </a-button>
      </div>
    </div>
  </div>

</template>

<script>
import {list,avatar,checkbox} from 'ant-design-vue';
import Vue from 'vue';
import axios from 'axios'
Vue.use(list);
Vue.use(avatar);
Vue.use(checkbox);

export default {
  name: "UserManager",
  data() {
    return {
      data:[],
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      selectedUser: {nickname:'测试用户'},
      funOptions:['统计分析', '长势监测','农地收藏'],
      funKey:{'统计分析':1, '长势监测':2,'农地收藏':4},

    };
  },
  mounted() {
      var that =this;

    axios.get(this.config.wechatSvr+'system/queryAllUser').then(function(response){
      //that.data=response.data;
      for(var i in response.data){
        var dt = response.data[i];
        if(dt.level==8) continue;
        dt.isSelected=false;
        that.data.push(dt);
      }
    });
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.funOptions.length;
      this.checkAll = checkedList.length === this.funOptions.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.funOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    clickUser(item){
      for(var i in this.data){
         this.data[i].isSelected=false;
      }
      item.isSelected=true;
      this.selectedUser=item;
      this.setCheckedList(this.selectedUser.level);
    },
    setCheckedList(level){
      this.checkedList=[];
     if([1,3,5,7].indexOf(level)>=0){
       this.checkedList.push(this.funOptions[0]);
     }
      if([2,3,6,7].indexOf(level)>=0){
        this.checkedList.push(this.funOptions[1]);
      }
      if([4,5,6,7].indexOf(level)>=0){
        this.checkedList.push(this.funOptions[2]);
      }
    },
    updateUser(){
      var level=0;
      for(var i in this.checkedList){
        level+=this.funKey[this.checkedList[i]];
      }
      if(level!=this.selectedUser.level){
        this.selectedUser.level=level;
        var user={
          id:this.selectedUser.id,
          openid:this.selectedUser.openid,
          nickname:this.selectedUser.nickname,
          headimgurl:this.selectedUser.headimgurl,
          level:this.selectedUser.level,
        };
        axios.post(this.config.wechatSvr+'system/UpdataUser',user).then(function (response){
          console.log(response.data);
        });
      }
    },
  },

}
</script>

<style scoped>


</style>