<template>
  <div  >
    <div style="width: 100%;height: 40px;">
      <span style="color:white;font-size: small;margin-left: 5px;margin-right: 10px">收藏农地</span>
      <a-input size="small" placeholder="输入名称" style="width: 200px" @change="handleSearchInputChange" />

    </div>
    <div style="margin-left:5px;width: calc(100% - 8px);height: calc(100% - 40px);overflow-y: auto">
      <template v-for="(tag, index) in tags">
        <a-tag :key="tag.label" :visible="tag.visible" :closable="index !== 0" @close="() => handleClose(tag)" @click="()=>handleClick(tag.label)">
          {{ tag.label }}
        </a-tag>
      </template>
      <a-input
          v-if="inputVisible"
          ref="input"
          type="text"
          size="small"
          :style="{ width: '90px' }"
          :value="inputValue"
          @change="handleInputChange"
          @blur="handleInputConfirm"
          @keyup.enter="handleInputConfirm"
      />
      <a-tag v-else style="background: #fff; borderStyle: dashed;width: 90px"  @click="showInput">
        <a-icon type="plus" />新增地块
      </a-tag>
    </div>
    <a-alert  id="alertMsg"   v-if="alertVisible"
              :message="alertMsg"
              type="success"
              closable
              show-icon
    />

    <a-modal
        title="删除收藏"
        :visible="dlgVisible"

        @ok="handleOk"
        @cancel="handleCancel"
    >
      <p>{{ dlgText }}</p>
    </a-modal>

  </div>
</template>

<script>

import Vue from 'vue';
import {Tag,Input,Modal} from 'ant-design-vue';
import axios from 'axios'
Vue.use(Tag);
Vue.use(Input);
Vue.use(Modal);
export default {
  name: "Remark",
  data() {

    return{
      tags: [{label:'全区域',visible:true}],

      geometrys:{},
      inputVisible: false,
      inputValue: '',
      searchTagValue:'',
      alertVisible:false,
      alertMsg:'',
      dlgVisible:false,
      dlgText:'',
      removeTag:'',
      removeTagIdx:-1

    }

  },
  props:{
    mapView: {
      type: Object,
      default: () => ({})
    },
    selectedGraphic: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.getRemarks();

  },
  methods: {
    handleClose(removedTag) {
      this.removeTag=removedTag;
      this.removeTagIdx=this.tags.indexOf(removedTag);
      this.dlgVisible=true;
      this.dlgText="是否确定删除收藏:"+removedTag.label+"?";
       return false;

    },
    handleOk(){
      var params="userId="+this.user.openid +"&"+"name="+this.removeTag.label;

      var that =this;
      axios.post(this.config.wechatSvr + "bookmark/delete?"+params).then(function(response){
         if(response.data.success){

           const tags = that.tags.filter(tag => tag !== that.removeTag);

           console.log(tags);
           that.tags = tags;


         }else {
           that.showAlert("删除失败！");

           that.tags.splice(that.removeTagIdx,0,that.removeTag);
         }
      });
      this.dlgVisible=false;
    },
    handleCancel(){

      this.dlgVisible=false;
      //this.tags.splice(this.removeTagIdx,0,this.removeTag);

    },
    handleClick(tag){

      if(tag=='全区域'){
        this.mapView.center=this.config.mapCenter.center;
        this.mapView.zoom=this.config.mapCenter.zoom;
      }else {
        console.log(tag);
        var geometry = this.geometrys[tag];
        this.$emit('showRemark',geometry);

      }

    },
    showInput() {
      if(this.selectedGraphic.geometry){
        this.inputVisible = true;
        this.$nextTick(function() {
          this.$refs.input.focus();
        });
      }else
      {

        this.showAlert("请先选择要添加的地块！");
      }

    },
    getRemarks(){
      var params={

        userId:this.user.openid

      };
      var that=this;
      axios.get(this.config.wechatSvr + "bookmark/queryByUserid",{params}).then(function(response){
        console.log(response.data.length);
          for(var i in response.data){

            var rm=response.data[i];
            var tagName =rm['name'];
            var geometry =rm['geometry'];
            that.tags.push({label:tagName,visible:true});
            that.geometrys[tagName]=JSON.parse(geometry);
            console.log(tagName);
          }
      });

    },
   showAlert(msg){
     this.alertMsg=msg;
     this.alertVisible=true;
     var that=this;
     setTimeout(function(){
       that.alertVisible=false;
     },1000)
   },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.tags;
      if(inputValue=='')
      {
        this.inputVisible = false;
        return;
      }
      if (inputValue && tags.indexOf(inputValue) >=0) {

        this.showAlert("该名称已经存在，请输入其他名称！");
        return;
      }
      var parm={
        "geometry":JSON.stringify(this.selectedGraphic.toJSON()),//.replace(/"/g,"'"),
        "id": 0,
        "name": inputValue,
        "userid": this.user.openid
      };
      var that=this;



      axios.post(this.config.wechatSvr + "bookmark/add", parm, {
        headers: { 'Content-Type' : 'application/json' }
      }).then(function (result) {
        if(result.data.success){
          that.geometrys[inputValue]=that.selectedGraphic.toJSON();
          tags = [...tags, {label:inputValue,visible:true}];
          Object.assign(that, {
            tags,
            inputVisible: false,
            inputValue: '',
          });
        }else {
          that.showAlert("提交出错！");
        }

      });




    },

    handleSearchInputChange(e){

      this.searchTagValue = e.target.value;
      console.log('搜索'+this.searchTagValue);
      for(var i=1;i<this.tags.length;i++){
        var tg=this.tags[i];

        if(tg.label.indexOf(this.searchTagValue)<0){
           tg.visible=false;
        }else {
          tg.visible=true;
        }
      }
    },

  }
}
</script>

<style scoped>

</style>