<template>
  <div>
     <div id="chartDiv" class="chartClass">

    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: "NDVIChart",
  data() {
    return {
      chartTitle:'',
      theChar:undefined
    }
  },
  props:{
    selectedGraphic: {
      type: Object,
      default: () => ({})
    },
    baseline: {
      type: Object,
      default: () => ({})
    }
  },
  mounted(){

  },
  methods: {
    showChar(){
      console.log('绘制图表');
      var chartDiv=document.getElementById("chartDiv");
      if(!this.theChar)
      {

        chartDiv.style.width = window.innerWidth - 20 + "px";
        this.theChar= echarts.init(chartDiv);

      }
      var attribute=this.selectedGraphic.attributes;
      var baseValue=undefined;
      for(var i=0;i<this.baseline.values.length;i++){

        var bl=this.baseline.values[i].attributes;
        if(bl["Field1"]==attribute["FL"])
        {
          baseValue=bl;
          console.log(bl);
          break;
        }

      }
      var xAdata=[];
      var bData=[];
      var cData=[];
      if(baseValue&&attribute){

        for(var  fd in this.config.ndviFieldsMap){
            var fdInfo=this.config.ndviFieldsMap[fd];
            var xval=fdInfo[0];//字段中文名
            var yfd =fdInfo[1];//数据表中的字段名
            //fd为基准线中的字段名
            xAdata.push(xval);
             bData.push(baseValue[fd]);
             cData.push(attribute[yfd]);
        }

      }
      var option = {
        legend:{
          data:["长势基准线","长势线"]
        },
        xAxis: {
          type: 'category',
          data: xAdata,
          axisLabel: {
            rotate: 40, // 旋转30度
            show: true, //这行代码控制着坐标轴x轴的文字是否显示
            textStyle: {
              color: '#000',   //x轴上的字体颜色
              fontSize: '12' // x轴字体大小
            }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name:"长势基准线",
            data: bData,
            type: 'line',
            smooth: true
          },
          {
            name:"长势线",
            data: cData,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log(option);
      this.theChar.setOption(option);
      this.theChar.resize();
      var that=this;
      chartDiv.style.width = "100%";
      window.addEventListener('resize',function() {
        chartDiv.style.width = "100%";
        that.theChar.resize();
      });
    }
  },
  watch: {

  },
}
</script>

<style scoped>
.chartClass{
  width: 100%;
  height:270px;
  margin: 0 auto;
}
.chartTitle{

  width: 100%;
  font-size: x-small;
  font-weight: bold;
  height: 30px;

}
</style>