<template>
  <div style="padding:8px;overflow-y: auto">
    <div>
      <a-switch default-checked checked-children="显示已回复" un-checked-children="隐藏已回复" @change="showReolyed" />

    </div>
    <div id="opinionDiv" style="overflow-y: auto">
      <a-list

          v-if="comments.length"
          :data-source="comments"
          header="意见列表"
          item-layout="horizontal"
      >

        <a-list-item v-show="shiwReplyed||!item.isReply" slot="renderItem" slot-scope="item">
          <OpinionItem :opinion-item="item" ></OpinionItem>
        </a-list-item>
      </a-list>
    </div>


  </div>
</template>

<script>
import {list,avatar,comment} from 'ant-design-vue';
import Vue from 'vue';
import OpinionItem from "@/components/OpinionItem";
import axios from 'axios'
Vue.use(list);
Vue.use(avatar);
Vue.use(comment);
Vue.use(OpinionItem);
export default {
  name: "OpinionManager",
  components: { OpinionItem},
  data() {
    return {
      comments: [],
      shiwReplyed:true
    };
  },
  mounted() {
    var that =this;
    axios.get(this.config.wechatSvr+'system/queryAllOpinion').then(function(response){
      //that.data=response.data;
      console.log("获取所有问题");
      for(var i in response.data){
        var dt = response.data[i];
        if(dt.request) dt.isReply=true;
        else dt.isReply=false;
        that.comments.push(dt);
      }

    });
    document.getElementById("opinionDiv").style.height=window.innerHeight-150+ "px";
    window.addEventListener('resize', function () {
      document.getElementById("opinionDiv").style.height=window.innerHeight-150+ "px";
    });
  },
  methods:{
    showReolyed(){
      this.shiwReplyed=!this.shiwReplyed;
    }

  },

}
</script>

<style scoped>

</style>