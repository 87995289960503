<template>
  <div  style="width: 100%;height: 100%">
    <div v-if="bGrant" id="updiv">
      <a-layout  v-show="!isSetting"  id="main-div">

        <a-layout >
          <a-layout >

            <a-layout-content :style="{ background: '#fff', margin: 0, minHeight: '310px' }">
              <div   id="MapView"/>
              <div id="toolbar" >
                <span class="menu">地块</span>
                <a-switch default-checked style="padding-right: 15px;margin-right: 5px" @change="switchOnChange">
                  <a-icon slot="checkedChildren" type="check" />
                  <a-icon slot="unCheckedChildren" type="close" />
                </a-switch>

                <a-button type="primary" style="top:5px" @click="doNDVI" v-show="[2,3,6,7,8].indexOf(level)>=0" >
                  长势预测
                </a-button>



                <a-switch checked-children="显示收藏" size="default" v-show="[4,5,6,7,8].indexOf(level)>=0" un-checked-children="关闭收藏" style="float:right;margin-right: 5px;margin-top: 8px;height: 30px" @change="bookmarkSwOnChange" />
                <a-switch checked-children="显示图例" size="default" un-checked-children="关闭图例" style="float:right;margin-right: 5px;margin-top: 8px;height: 30px" @change="legendSwOnChange" />

              </div>
              <Remark id="tags" :map-view="mapView" @showRemark="showRemark" :user="user" :selected-graphic="selectedGraphic" v-show="bBookmark">



              </Remark>

              <ZoneSelect  v-show="!isSetting" id="selectZone" ref = "zSelect"  @changedZone="doFilter"/>


              <div id="NDVIDiv" v-show="bNDVI">
                <span style="position: absolute;left:8px;color:black;">长势监测-{{zwlb}}</span>

                <NDVIChart ref="ndvi" class="ndviClass" :selected-graphic="selectedGraphic" :baseline="baseValues" />
                <a-button type="danger" shape="circle" style="position: absolute;top:5px;right:5px" icon="close" @click="closeNDVI">
                </a-button>
              </div>


              <div id="legendDiv" v-show="bLegend">
                <span>图例</span>
                <div id="legend" />

              </div>

              <a-button id="selectBtn"  :type="inSelect?'danger':'primary'" shape="circle" icon="select" @click="doSelect" />
              <a-button id="systemBtn" v-if="level==8" type='primary' shape="circle" icon="setting" @click="doSetting" />
<!--              <a-button id="remarkBtn" v-show="bBookmark" shape="circle" icon="tags" @click="doSelect" />-->

              <a-alert  id="alertMsg"   v-if="alertVisible"
                        :message="alertMsg"
                        type="success"
                        closable
                        show-icon
                        :after-close="alertClose"
              />

            </a-layout-content>
          </a-layout>
        </a-layout>
      </a-layout>
      <div v-if="isSetting"  style="height: 100%;width: 100%">
        <SystemManager id="systemDiv"></SystemManager>
        <a-button type="danger"   v-if="isSetting" shape="circle" style="position: absolute;top:5px;right:5px" icon="close" @click="closeSetting">
        </a-button>
      </div>
    </div>
    <div style="width: 100%;height: 30px;background-color: #ffffff;text-align: center">
      <span v-if="notice" style="margin-right: 20px">所有数据均为模型计算数据！无实际意义！</span>
      <a href="https://beian.miit.gov.cn" >网站备案：粤ICP备2021174909号-3</a>


    </div>
  </div>

</template>
<script>
import Vue from 'vue';

//import "@arcgis/core/assets/esri/themes/dark/main.css";
import {Layout, Collapse, Icon, Breadcrumb, Switch, Alert} from 'ant-design-vue';

// Default import
import { loadModules } from "esri-loader";
import Remark from "./components/Remark.vue";
import NDVIChart from "@/components/NDVIChart";
import axios from 'axios'
import ZoneSelect from "@/components/ZoneSelect";
import SystemManager  from "@/components/SystemManager";
//import CachedLayer from "./components/CachedLayer";

Vue.use(Layout);
Vue.use(Collapse);
Vue.use(Icon);
Vue.use(Breadcrumb);
Vue.use(SystemManager);
Vue.use(Switch);
Vue.use(Remark);
Vue.use(NDVIChart);
Vue.use(Alert);
export default {
  components: {ZoneSelect,SystemManager},
  data() {
    return {
      bGrant:false,
      mapView:undefined,
      dikuaiLayer:undefined,
      featureLayer:undefined,
      bLegend:false,
      bBookmark:false,

      bNDVI:false,
      inSelect:false,
      isSetting:false,
      mapClickEvent:undefined,
      alertVisible:false,
      alertMsg:"",
      graphicLayer:undefined,
      selectedGraphic:undefined,
      zoneGraphic:undefined,
      esri:undefined,
      baseValues:undefined,
      zwlb:"未选择",
      user:undefined,
      // user:{
      //      nickname:'孙大圣',
      //      openid:'okDJH6_YWJoCJwSA8K0g52ZXFJEw',
      //      id:25,
      //      headimgurl:'http://thirdwx.qlogo.cn/mmopen/fy3yHcoNCGJanGzQkhCI4Akxda2AUYoyfsBMFSg7vOtfKDnFZ8A6YYic0o80oricQeqD7WyWNXbSOibqwosPkjvVYgcoZ4JxsWb/132',
      //      level:8
      //    },
      level:0,
      notice:false

    };
  },

  mounted() {

     this.checkUser();


  },
  methods:{

    switchOnChange(checked){
      if(this.dikuaiLayer){
        this.dikuaiLayer.visible=checked;
      }

    },
    checkUser(){
      console.log("获取用户认证");
      //this.alertMsg="获取用户认证";
      var code =this.GetRequest().code;
      //alert(code);
      if(!code){
        this.bGrant=true;
        this.importEsri();
        this.showLegend();
        this.notice=true;
        return;
      }
      this.notice=false;
      if(!this.user){

         var that=this;

        axios.get(this.config.wechatSvr+'WechatHandler/GetSNSAcceass_token?code='+code).then(function(response){
            //alert(JSON.stringify(response.data));
          if(response.data.success){
            that.user=response.data.data.data;
            that.bGrant=true;
            that.importEsri();
            that.showLegend();
            that.level=that.user.level;
            //alert(JSON.stringify(that.user));
          }else
          {
            //alert("您没有权限登陆系统！");
            that.bGrant=false;
          }

        });
      }else {
        this.bGrant=true;
        this.importEsri();
        this.showLegend();
        this.level=this.user.level;
      }
    },
    GetRequest() {
      try{
        let url = location.href.split("?")[1].split("&"); //获取url中"?"符后的字串

        console.log(url);
        let query = {};
        url.forEach(item => {
          query[item.split("=")[0]] = decodeURIComponent(item.split("=")[1]);  //转码
        });
        return query;
      }catch (e) {
        return {code:undefined};
      }

    },
    legendSwOnChange(checked){

      this.bLegend=checked;


    },
    bookmarkSwOnChange(checked){

      this.bBookmark=checked;


    },
    alertClose(){
      this.alertVisible = false;
    },
    doSetting(){
      this.isSetting=true;
    },
    closeSetting(){
      this.isSetting=false;
    },
    doSelect(){
      this.inSelect=!this.inSelect;
      if(this.inSelect){
        if(this.mapView){
          this.mapClickEvent=  this.mapView.on('click',this.queryFeatures);

        }
      }else
      {
        this.mapClickEvent.remove();
        this.graphicLayer.removeAll();
        this.alertClose();
      }

    },
    closeNDVI(){
      this.bNDVI=false;
    },


    doNDVI(){

      this.bNDVI=true;
      this.$refs.ndvi.showChar();
    },
    getBaselineValue(){
      var that =this;
      axios.get(this.config.baselineLayer).then(function(response){
        that.baseValues={
          fields:response.data.fieldAliases,
          values:response.data.features
        };

      });


    },
    doFilter(){

      var zoneCode = this.$refs["zSelect"].selectedCode;
      console.log(zoneCode);
      var zclst=zoneCode.split(",");
      var where="";
      for(var i=0;i<zclst.length;i++){
        where+="XZQDM like '"+zclst[i]+"%' OR "
      }
      where=where.substr(0,where.length-4);
      console.log(where);
      if(this.dikuaiLayer){
        this.dikuaiLayer.sublayers.items[0].definitionExpression=where;
      }
      zoneCode=zclst[0];

      var qType="xian";
      switch (zoneCode.length){
       case 6:
         qType='xian';
         zoneCode='654202020';
         break;
       case 9:
         qType='xiang';
         break;
       default:
         qType='cun';
         zoneCode="'"+zoneCode+"'";
         break;
      }
      var layerUrl =this.config.zoneLayer[qType];
      console.log(layerUrl);

      let queryTask = new this.esri.QueryTask({
        url: layerUrl
      });
      let query = new this.esri.Query();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.where = "XZQDM="+zoneCode;  // Return all cities with a population greater than 1 million
      var that=this;
      // When resolved, returns features and graphics that satisfy the query.
      queryTask.execute(query).then(function(results){
        console.log(results.features);
        if(!that.zoneGraphic){
          that.zoneGraphic = new that.esri.Graphic({


            symbol: {
              type: "simple-fill",  // autocasts as new SimpleFillSymbol()
              color: [0, 255, 255, 0],
              outline: {  // autocasts as new SimpleLineSymbol()
                color: [255,140,0, 1.0],
                width: "5.0px"
              }
            }

          });
          that.graphicLayer.add(that.zoneGraphic);
        }
        that.zoneGraphic.geometry=results.features[0].geometry;
        that.mapView.goTo(that.zoneGraphic.geometry);

      });

    },
    queryFeatures(e){
      //this.mapClickEvent.remove();
      var mapPoint =e.mapPoint;
      var that=this;
      this.featureLayer.queryFeatures({
        //query object
        geometry: mapPoint,
        spatialRelationship: "intersects",
        returnGeometry: true,
        outFields: ["*"],
      })
          .then((featureSet) => {
            var feature=featureSet.features[0];
            var att=feature.attributes;
            var geometry=feature.geometry;
            var flName=att['FL'];
            var area = Number(att['Shape_Area'])*0.0015;
            console.log(flName+geometry);
            that.alertMsg="选择了:"+flName+" "+area.toFixed(2)+"亩! ";
            that.alertVisible=true;
            that.selectedGraphic.geometry=geometry;
            that.selectedGraphic.attributes=att;
            that.graphicLayer.removeAll();
            that.graphicLayer.add(that.selectedGraphic);
            that.zwlb=flName;
            if(that.bNDVI)
              that.$refs.ndvi.showChar();

          });
    },
    showRemark(feature){

      var polygon=new this.esri.Polygon(feature.geometry);
      this.selectedGraphic.geometry=polygon;
      this.selectedGraphic.attributes=feature.attributes;
      this.graphicLayer.removeAll();
      this.graphicLayer.add(this.selectedGraphic);
      this.mapView.goTo(polygon);
      //alert('ok');

    },
    showLegend(){
      console.log('生成图例');
      axios.get(this.config.dikuai + "/legend?f=pjson").then(function(response){
        var tableHtml="<table>";
        var dikuaiLegend=response.data.layers[0].legend;
        for(var i=0;i<dikuaiLegend.length;i+=2){
          var legend1=dikuaiLegend[i];
          var label1=legend1.label;
          if(label1=='<all other values>') label1="默认";
          var img1=legend1.imageData;
          var tr1="<tr><td  style='height:30px;width: 30px '><img src='data:image/png;base64,"+img1+"' style='height: 20px;width: 20px'/></td><td  style='height:30px;width: 90px '>"+label1+"</td>";
          tableHtml+=tr1;
          if(i+1<=dikuaiLegend.length-1){
            var legend2=dikuaiLegend[i+1];
            var label2=legend2.label;
            var img2=legend2.imageData;
            var tr2="<td  style='height:30px;width: 30px '><img src='data:image/png;base64,"+img2+"' style='height: 20px;width: 20px'/></td><td  style='height:30px;width: 90px '>"+label2+"</td></tr>";
            tableHtml+=tr2;

          }else {
            tableHtml+="<td ></td><td ></td></tr>";
          }


        }
        tableHtml+="</table>";
        document.getElementById("legend").innerHTML=tableHtml;

      });

    },
    async importEsri() {

      try{
        //this.alertMsg="加载ESRI内容";

      let options = {
          // css: "./api/esri/themes/light/main.css",
          // url: "./api/init.js"
          //css:"https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css"
          url:"https://heaven5.tech/api/init.js",
          css:"https://heaven5.tech/api/esri/themes/light/main.css"
          //url:"http://120.24.89.176:8080/api/init.js",
          //css:"http://120.24.89.176:8080/api/esri/themes/light/main.css"
      };



      loadModules([
          "esri/Map",
          "esri/views/MapView","esri/views/SceneView",
          "esri/layers/TileLayer",
          "esri/layers/MapImageLayer",
          "esri/layers/FeatureLayer",
          "esri/layers/GraphicsLayer",
          "esri/layers/WebTileLayer",
          "esri/layers/support/TileInfo",
          "esri/geometry/Extent",
          "esri/layers/support/LOD",
          "esri/Graphic",
          "esri/geometry/Polygon",
          "esri/tasks/QueryTask",
          "esri/tasks/support/Query"
      ], options).then(
          ([Map, MapView,SceneView, TileLayer, MapImageLayer, FeatureLayer, GraphicsLayer,WebTileLayer,TileInfo,Extent, LOD,Graphic, Polygon,QueryTask,Query]) => {
            this.esri = {
              Map: Map,
              MapView: MapView,
              SceneView:SceneView,
              TileLayer: TileLayer,
              MapImageLayer: MapImageLayer,
              FeatureLayer: FeatureLayer,
              GraphicsLayer: GraphicsLayer,
              WebTileLayer:WebTileLayer,
              TileInfo:TileInfo,
              Extent:Extent,
              LOD:LOD,
              Graphic: Graphic,
              Polygon: Polygon,
              QueryTask:QueryTask,
              Query:Query

            };
            //this.alertMsg="加载ESRI内容完成";
            this.createView();

            this.getBaselineValue();

          }
      );
      }catch (e){
        //alert(e.message);
        //this.alertMsg="加载ESRI内容错误"+e.message;
      }

    },

    async createView() {
      const baseimgLayer = new this.esri.TileLayer({
        url: this.config.baseimg,
        id: 'basemap'
      });
      const basemapLayer = new this.esri.MapImageLayer({
        url: this.config.baseMap,
        id: 'basemap'
      });

      // var tiledLayer = new this.esri.WebTileLayer({
      //   urlTemplate:
      //       "http://{subDomain}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={level}&TILEROW={row}&TILECOL={col}&tk=c2dc60dcc763e26506c1ef475a443680",
      //   subDomains: ['t0','t1','t2','t3','t4','t5','t6','t7'],
      //   title:"tdtImg",
      //   copyright: ''
      // });


      var tiledLayerLabel = new this.esri.WebTileLayer({
        urlTemplate:
            "https://{subDomain}.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={level}&TILEROW={row}&TILECOL={col}&tk=c2dc60dcc763e26506c1ef475a443680",
        subDomains: ['t0','t1','t2','t3','t4','t5','t6','t7'],
        title:"tdtLabel",

        copyright: '天地图'
      });
      //this.alertMsg="加载天地图图层完成";

//console.log(tiledLayer);
            this.featureLayer = new this.esri.FeatureLayer({url: this.config.dikuai + "/"});
            this.dikuaiLayer = new this.esri.MapImageLayer({
              url: this.config.dikuai,
              id: 'dikuai',
              maxScale:0,
              minScale:300000
            });
            this.graphicLayer = new this.esri.GraphicsLayer();
            this.selectedGraphic = new this.esri.Graphic({


              symbol: {
                type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                color: [0, 255, 255, 0.1],
                outline: {  // autocasts as new SimpleLineSymbol()
                  color: [0, 255, 255, 1.0],
                  width: "3.0px"
                }
              }

            });
            //var tdtLayer=new CachedLayer("http://{subDomain}.tianditu.gov.cn/{mapType}/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={x}&TILECOL={y}&tk=c2dc60dcc763e26506c1ef475a443680","png","img_c");
            let map = new this.esri.Map({
              // layers:  [tiledLayer,basemapLayer,baseimgLayer,this.dikuaiLayer,tiledLayerLabel, this.graphicLayer]
              layers:  [baseimgLayer,this.dikuaiLayer,tiledLayerLabel,basemapLayer, this.graphicLayer]
            });

            this.mapView = new this.esri.MapView({
              container: "MapView",
              map: map,
              background: {
                color: "black"
              },
              widthBreakpoint: 'large'
            });

            this.mapView.center = this.config.mapCenter.center;
            this.mapView.zoom = this.config.mapCenter.zoom;
            this.mapView.ui._removeComponents(["attribution"]);

     // this.alertMsg="加载d地图完成";
      var dom=document.getElementById("MapView");
      dom.style.height=window.innerHeight-30 + "px";
      window.addEventListener('resize', function () {
        dom.style.height=window.innerHeight-30 + "px";
      });
     // this.alertMsg="地图大小:"+dom.offsetWidth+","+dom.offsetHeight;
    }


  }
};
</script>

<style>
#main-div{
  min-height: 620px;
  height: 100%;
  font-size: large;
}
#main-div .leftSide{
  overflow-y: auto;

}
#main-div .title {
  font-weight:bold;
  float: left;
  font-size: larger;
  color: cornflowerblue;
}
#main-div .menu {
  font-size: small;
  color:white;
  padding-left: 5px;
  padding-right: 5px;


}
#main-div #MapView{
  width:  calc(100% - 0px);
  height:  calc(100% - 0px);
  overflow: no-display;
  bottom:0px;
  background-color: cornflowerblue;
}
#main-div #toolbar{
  width:  calc(100% - 0px);
  height:  60px;
  overflow: no-display;
  bottom:30px;
  position:absolute;
  background-color: #182026c2;
}
#main-div #tags{

  width:  350px ;
  height:  200px;
  overflow: no-display;
  bottom:90px;
  position:absolute;
  border:1px solid #fff;
  right: 5px;
  background-color: #182026c2;
  overflow-y: auto;
}
#main-div #legendDiv{

  width:  240px ;
  height:  300px;
  overflow: no-display;
  bottom:90px;
  position:absolute;
  border:1px solid #fff;
  left: 5px;
  background-color: #182026c2;
  color:white;
  font-size: small;
  overflow-y: auto;
}
#main-div #NDVIDiv{

  width:  calc(100% - 8px);
  height:  300px;
  overflow: no-display;
  bottom:90px;
  position:absolute;
  border:1px solid #fff;
  left: 5px;
  background-color: #f5f8fa;
  color:white;
  font-size: small;
  overflow-y: auto;
}

#selectBtn{

  position: absolute;
  bottom: 95px;
  right: 10px;

}
#systemBtn{

   position: absolute;
   bottom: 135px;
   right: 10px;

 }
#remarkBtn{

    position: absolute;
    bottom: 95px;
    right: 10px;

  }
#alertMsg{
  position: absolute;
  right:50px;
  bottom: 90px;
}
#selectZone{
  position:absolute;
  top:10px;
  width: calc(100% - 55px);
  height: 40px;
  left: 50px;
  min-width: 233px;
  max-width: 400px;

}
.ndviClass{
  width: calc(100% - 15px);;
  height: 270px;
  bottom:0px;
  position: absolute
}
.tongjiClass{
  width: calc(100% - 8px);
  height: calc(100% - 55px);
  top:30px;
  bottom:50px;
  position: absolute
}
#updiv{
  width: 100%;
  height:calc(100% - 30px);
}
#systemDiv{
  width: 100%;
  height:calc(100% - 30px);
  bottom:30px;
  overflow-y: auto;
  position: absolute;
  top: 0px;
  background-color: white;
}
</style>