<template>
  <div id="TongjiDiv" >
    <a-cascader
        ref="cascader"
        :options="options"
        placeholder="请选择行政区"
        change-on-select
        @change="onChange"
    />
    <div id="allCharDiv" >
      <span style="left:8px;color:black;">统计分析-{{tjname}}</span>
      <div  style="text-align: center">

        <span class="chartTitle" >{{chartTitle}}作物面积统计图</span>
        <div id="topChartDiv">

        </div>

        <span class="chartTitle" >{{chartTitle}}作物类型统计图</span>
        <div id="bottomChartDiv" >

        </div>

      </div>

      <a-button type="danger" shape="circle" style="position: absolute;top:5px;right:10px"  :loading="bUploading" icon="wechat" data-html2canvas-ignore={true}  @click="exportTongji"></a-button>

    </div>

  </div>


</template>

<script>
import Vue from 'vue';
import * as echarts from 'echarts';
import axios from "axios";
import html2canvas from "html2canvas";
//import domtoimage from 'dom-to-image';
import {cascader} from 'ant-design-vue';
Vue.use(cascader);

export default {
  name: "TongjiChart",
  data() {
    return {
      chartTitle:'乌苏乡镇',
      zoneInfo:undefined,
      tjname:"",
      bUploading:false,
      topChartDiv:undefined,
      bottomChartDiv:undefined,
      chartHeight:0,
      options: [

      ],

    }
  },
  props:{

  },
  mounted(){
    //this.options=JSON.parse(JSON.stringify(this.config.xiang)) ;
    this.options=[];
    for(var i in this.config.xiang){
      this.options.push({value: this.config.xiang[i].value, label: this.config.xiang[i].label});
    }
    this.doTongji('654202');
    document.getElementById("TongjiDiv").style.height=window.innerHeight - 90 + "px";
    window.addEventListener('resize', function () {
      document.getElementById("TongjiDiv").style.height=window.innerHeight - 90 + "px";
    });
  },
  methods: {
    onChange(value) {
        this.doTongji(value);
    },
    exportTongji(){
      this.bUploading=true;

      let that = this;


      setTimeout(function(){
        //var dom =document.getElementById('allCharDiv');
        var height=document.getElementById('allCharDiv').scrollHeight;
        html2canvas(document.getElementById('allCharDiv'),{
          backgroundColor: 'white',
          type: 'view',
          useCORS: true, //支持图片跨域
          scale: 1, //设置放大的倍数
          height:height,
          windowHeight:height,
          //y:window.pageYOffset,
          //scrollY:height,
          allowTaint:false,
          //height:height,
          //width:height,
        }).then(canvas => {
          //document.body.appendChild(canvas)
          var base64Img = canvas.toDataURL('image/png');
          console.log(base64Img);
          that.uploadImage(base64Img);
          that.bUploading=false;
        });

      },100);

    },
    uploadImage(imgStr){


      let param= new FormData();
      param.append("file", this.base64ToFile(imgStr,this.tjname));
      param.append("imgName", this.tjname);

      axios.post(this.config.wechatSvr + "system/uploadImg",param,{headers:{'Content-Type': 'multipart/form-data'}}).then(function(response){
        console.log(response.data);

      });
    },
    base64ToFile(urlData, fileName){
      let arr = urlData.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--) {undefined
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },

    getData(Url,lbFd,areaFd,where,pChartDiv,callbackFunction){

      var that=this;
      axios.get(Url + "/query?where="+where+"&outFields=*&returnGeometry=false&f=pjson").then(function(response){
          console.log(response.data);
          var features=response.data.features;
          var areaData={};
          for(var i in features){

            var feature=features[i];
            var code=feature.attributes[lbFd];
            var name=that.getNameByCode(code+"");
            var area =feature.attributes[areaFd];
            if(!areaData[name])areaData[name]=area;
            else areaData[name]+=area;

          }
          if(callbackFunction)
               callbackFunction(areaData,pChartDiv);
      });
    },
    doTongji(zoneCode){

      this.chartHeight=0;
      var tongjiObj=undefined;
      if(zoneCode==this.config.xian[0].value)
      {
        tongjiObj=this.config.xian[0];
      }else {
        var items=this.config.xiang.filter(function(item){
          return item.value==zoneCode;
        });
        if(items&&items.length>0)
        {
          tongjiObj=items[0];
        }
      }
      if(tongjiObj){
        this.tjname=tongjiObj.label;
        this.showChar(tongjiObj);
      }

    },
    getNameByCode(code){

      var items;
      if(code.length==6){
        items=this.config.xian.filter(function(it){
          return it.value==code;
        });

      }else if(code.length==9){

        items=this.config.xiang.filter(function(it){
          return it.value.indexOf(code)>=0;
        });

      }else
      {
        items=this.config.cun.filter(function(it){
          return it.value==code;
        });
      }
      var item;
      if(items&&items.length>0)item=items[0].label;
      else item=code;
      return item;
    },
    showChar(zonInfo) {
      console.log('绘制图表');
      this.zoneInfo = zonInfo;
      this.chartTitle = this.zoneInfo.label;

      this.bottomChartDiv = document.getElementById("bottomChartDiv");
      this.bottomChartDiv.innerHTML = "";
      this.topChartDiv = document.getElementById("topChartDiv");
      this.topChartDiv.innerHTML = "";


      var zclst = zonInfo.value.split(',');
      var where="";
      if (zclst[0].length == 6) {
        where="1%3D1";
        this.getData(this.config.tongjiUrl["all_area"], "xiang", "SUM_Shape_Area",where,this.topChartDiv, this.showAreaChar);
        this.getData(this.config.tongjiUrl["all_zuowu"], "FL", "SUM_Shape_Area",where,this.bottomChartDiv, this.showAreaChar);

      }else {

        for(var k in zclst){
          where+="XZQDM like '"+zclst[k]+"%' OR ";
        }
        where=encodeURIComponent(where.substr(0,where.length-4));

        this.getData(this.config.tongjiUrl["xiang_area"], "XZQDM", "SUM_Shape_Area",where,this.topChartDiv, this.showAreaChar);
        this.getData(this.config.tongjiUrl["xiang_zuowu"], "FL", "SUM_Shape_Area",where,this.bottomChartDiv, this.showAreaChar);
      }
    },

    showAreaChar(dataSet,pChartDiv){
      var dt={
        xAxis:[],
        yAxis:[]

      };

      var j=1;
      var sum_area=0;
      var max=0;
      var itemCount=11;
      var dtLength=0;
      for(var l in dataSet) {
        dtLength++;
        console.log(l);
      }
      if(dtLength<=itemCount) itemCount=dtLength;
      if(dtLength>itemCount) itemCount=Math.ceil(dtLength/2);
      for(var d in dataSet){
        var area=dataSet[d]*0.0015;
        area=Number(area.toFixed(2));
        sum_area+=area;
        if(max<area) max=area;
        console.log(d+":"+area)
        dt.xAxis.push(d);
        dt.yAxis.push(area);
        if(j%itemCount==0||j==dtLength){

          var chartDiv= document.createElement("div");
          pChartDiv.appendChild(chartDiv);
          this.refreshChar(chartDiv,[].concat(dt)[0],sum_area,max);
          dt={
            xAxis:[],
            yAxis:[]

          };

        }
        j++;
      }

    },
    refreshChar(chartDiv,dt,sumarea,max){




      chartDiv.style.width = window.innerWidth - 20 + "px";
      chartDiv.style.height = "270px";
       var theChar = echarts.init(chartDiv);


      var option = {
        title: {
          left: '24px',//主副标题的水平位置
           subtext:'单位：亩',      //副标题
          subtextStyle:{          //对应样式
            color:'#0DB9F2',
            fontSize:14
          },
        },
        xAxis: {
          type: 'category',
          data: dt.xAxis,
          axisLabel: {
            rotate: 40, // 旋转30度
            show: true, //这行代码控制着坐标轴x轴的文字是否显示
            textStyle: {
              color: '#000',   //x轴上的字体颜色
              fontSize: '12' // x轴字体大小
            }
          }
        },
        yAxis: {
          type: 'value',
          max:max,
          axisLabel: {
            formatter: function(val) {

              var ret = "";
              if (val >= 10000) ret = ((val / 10000).toFixed(1)).replace('.0', '') + "万";
              else if (val >= 1000 && val < 10000) ret = (val / 1000).toFixed(0) + "千";
              else ret = val;
              return ret;
            }
          },
          rotate:40
        },
        series: [
          {
            data:  dt.yAxis,
            itemStyle: {
              normal: {
                 color: function(params) {
                       var colorList = [
                           '#C1232B','#B5C334','#FCCE10','#E87C25','#27727B',
                            '#FE8463','#9BCA63','#FAD860','#F3A43B','#60C0DD',
                            '#D7504B','#C6E579','#F4E001','#F0805A','#26C0C0'
                         ];
                       return colorList[params.dataIndex]
                   },
                 label: {
                       show: true,
                       position: 'top',
                       formatter:function(params){
                         //console.log(params);
                         var bl=params.data/sumarea*100;
                         return ''+bl.toFixed(2)+"%";
                       }
                   }
             }
                   },
            type: 'bar'
          }
        ]
      };
      console.log(option);
      theChar.setOption(option);
      theChar.resize();
      this.chartHeight+=320;
      document.getElementById("allCharDiv").style.height=this.chartHeight + "px";
      window.addEventListener('resize', function () {
        chartDiv.style.width = "100%";
        theChar.resize();

      });
    }
  },
  watch: {

  },
}
</script>

<style scoped>
#bottomChartDiv{
   width: 100%;

   margin: 0 auto;
 }
#topChartDiv{
  width: 100%;

  overflow-y: auto;
  margin: 0 auto;
}
.chartTitle{

  width: 100%;
  color:black;
  font-size: x-small;
  height: 30px;

}
#TongjiDiv{

  width:  calc(100% - 8px);
  height: calc(100% - 8px) ;
  position:absolute;
  border:1px solid #fff;
  left: 5px;
  background-color: #f5f8fa;
  color:white;
  font-size: small;
  overflow-y: auto;
}
#allCharDiv{
  position: absolute;
  top:30px;
  width: 100%;

  bottom:5px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>