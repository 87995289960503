<template>
  <a-cascader
      ref="cascader"
      :options="options"
      :load-data="loadData"
      placeholder="请选择行政区"
      change-on-select
      @change="onChange"
  />
</template>

<script>
import Vue from 'vue';
import {cascader} from 'ant-design-vue';
Vue.use(cascader);
export default {
  name: "ZoneSelect",
  data() {
    return {
      options: [

      ],
      selectedCode:"",
      tongjiCode:""
    };
  },
  mounted() {
   this.options=this.config.xian;

  },
  methods: {
    onChange(value) {
      var lastVal=value[value.length-1];

      if(value.length==1) this.tongjiCode=value[0];
      else if(value.length>1)this.tongjiCode=value[1];
      console.log(this.tongjiCode);

      this.selectedCode=lastVal;
      this.$emit('changedZone')
      this.$refs.cascader.blur();
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      // load options lazily
      setTimeout(() => {
        targetOption.loading = false;
         var tval=targetOption.value;
         var that=this;
         if(tval.length==6){
           targetOption.children=this.config.xiang.filter(function(item){
              item.isLeaf=false;
              // var itemlst=item.value.split(',');
              // var b=false;
              // for(var i=0;i<itemlst.length;i++){
              //   b=b||that.config.valiListXiang.indexOf(itemlst[i])<0;
              // }
              // item.disabled=b;//that.config.valiListXiang.indexOf(item.value)<0;
              return item.value.startsWith(tval);
           });
         }else
         {
           targetOption.children=this.config.cun.filter(function(item){

             item.disabled=that.config.valiListCun.indexOf(item.value)<0;

             var tvalst=tval.split(',');
             for(var i =0;i<tvalst.length;i++){
               if(item.value.startsWith(tvalst[i])) return true;
             }
             return false;
           });
         }

        this.options = [...this.options];
      }, 500);
    },
  },
}
</script>

<style scoped>

</style>