
<template>
  <div>
    <a-comment>
      <span slot="actions" @click="enableReply">回复</span>
      <a slot="author">{{opinionItem.nickname}}</a>
      <a slot="datetime">{{opinionItem.submittime}}</a>
      <a-avatar
          slot="avatar"
          :src="opinionItem.headimgurl"
          :alt="opinionItem.nickname"
      />
      <p slot="content">
        {{opinionItem.matter}}
      </p>
    </a-comment>
    <a-comment>
      <a slot="author">管理员</a>
      <a-avatar
          slot="avatar"
          src="./admin.png"
          alt="管理员"
      />
      <a slot="datetime">{{opinionItem.replytime}}</a>

      <div slot="content">

        <a-textarea v-if="!opinionItem.isReply" :rows="4" :value="value" @change="handleChange"></a-textarea>

        <a-button v-if="!opinionItem.isReply" :loading="submitting" type="primary" @click="handleSubmit">
          回复
        </a-button>

        <a v-if="opinionItem.isReply">{{opinionItem.request}}</a>
      </div>
    </a-comment>
    <a-alert  id="alertMsg"   v-if="alertVisible"
              :message="alertMsg"
              type="success"
              closable
              show-icon
    />
  </div>
</template>

<script>
import {list,avatar,comment,button} from 'ant-design-vue';
import Vue from 'vue';
import axios from 'axios'
Vue.use(list);
Vue.use(avatar);
Vue.use(comment);

Vue.use(button);
export default {
  name: "OpinionItem",
  data() {
    return {
      comments: [],
      submitting: false,
      alertVisible:false,
      alertMsg:'',
      value:''

    };
  },
  props:{
    opinionItem: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
      this.value=this.opinionItem.request;
  },
  methods:{
    handleSubmit() {
      if (!this.value||this.value==this.opinionItem.request) {
        this.showAlert("回复内容为空或者没有修改内容，不能提交！");
        return;
      }
      var that = this;
      this.submitting = true;
      this.opinionItem.request=this.value;
      axios.post(this.config.wechatSvr+'system/updataOpinion',this.opinionItem).then(function(res){

        console.log(res);
        if(res.data.success)
        {
          that.submitting = false;
          that.opinionItem.isReply=true;
          that.showAlert("回复成功！");
        }else
        {
          that.showAlert("回复信息失败！请检查网络或者联系管理员~！");
        }


      }).catch(function(error){
        console.log(error);
        that.showAlert("回复信息失败！请检查网络或者联系管理员~！");
        that.submitting = false;
      });

    },
    showAlert(msg){
      this.alertMsg=msg;
      this.alertVisible=true;
      var that=this;
      setTimeout(function(){
        that.alertVisible=false;
      },4000)
    },
    handleChange(e) {
      this.value = e.target.value;
    },
    enableReply(){
      if(!this.request){
        this.opinionItem.isReply=false;
      }else {
        this.opinionItem.isReply=!this.opinionItem.isReply;
      }


    },
  },

}
</script>

<style scoped>

</style>