import Vue from 'vue';
//import Button from 'ant-design-vue/lib/button';
//import 'ant-design-vue/dist/antd.css';
import { Button } from 'ant-design-vue';
import App from './App.vue';
import echarts from 'echarts';
import config from  './data/config';
import Remark from './components/Remark.vue';
import NDVIChart from './components/NDVIChart.vue';
Vue.component(Button.name,Button);
Vue.component(Remark.name,Remark);
Vue.component(NDVIChart.name,NDVIChart);

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts
Vue.prototype.config=config;
//Vue.prototype.user=undefined;
// Vue.prototype.user= {
//   nickname:'孙大圣',
//   openid:'okDJH6_YWJoCJwSA8K0g52ZXFJEw',
//   id:25,
//   headimgurl:'http://thirdwx.qlogo.cn/mmopen/fy3yHcoNCGJanGzQkhCI4Akxda2AUYoyfsBMFSg7vOtfKDnFZ8A6YYic0o80oricQeqD7WyWNXbSOibqwosPkjvVYgcoZ4JxsWb/132',
//   level:8
// };
new Vue({
  render: h => h(App),
}).$mount('#app')
