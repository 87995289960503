<template>
<div >
  <a-tabs id="sysMainDIV" default-active-key="1" tab-position="top">
    <a-tab-pane key="1" tab="统计分析">
<TongjiChart></TongjiChart>
    </a-tab-pane>
    <a-tab-pane key="2" tab="用户权限管理">
      <UserManager></UserManager>
    </a-tab-pane>
    <a-tab-pane key="3" tab="意见回复">
      <OpinionManager></OpinionManager>
    </a-tab-pane>

  </a-tabs>

</div>
</template>

<script>
import Vue from 'vue';
import {tabs} from 'ant-design-vue';
import UserManager from "@/components/UserManager";
import TongjiChart from "@/components/TongjiChart";
import OpinionManager from "@/components/OpinionManager";
import axios from "axios";
Vue.use(UserManager);
Vue.use(TongjiChart);
Vue.use(tabs);
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {OpinionManager, UserManager,TongjiChart},
  name: "SystemManager",
  mounted() {
    axios.get(this.config.wechatSvr+'WechatHandler/RefreshUser');
  }
}
</script>

<style scoped>
#sysMainDIV{
  position: absolute;
  width: 100%;
  height:100%;

}
</style>